import React from "react";
import styled from "styled-components";
import { Field, Label, Input, Textarea, Message, Toggle } from "@zendeskgarden/react-forms";
import { Tag } from "@zendeskgarden/react-tags";
import {
  Item,
  Menu,
  Dropdown,
  Autocomplete,
  Field as DropdownField,
  type IDropdownProps,
  Multiselect,
} from "@zendeskgarden/react-dropdowns";
import {
  Formik,
  Field as FormikField,
  Form,
  type FormikHelpers,
  type FormikProps,
  type FieldProps,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "urql";
import { Button } from "@zendeskgarden/react-buttons";
import uniqBy from "lodash/uniqBy";
import { SortHelper } from "@linear/common/utils/SortHelper";
import {
  CreateAttachmentMutation,
  CreateIssueMutation,
  type ICreateAttachmentMutation,
  type ICreateIssueMutation,
  type ITeamQuery,
  type IViewerQuery,
  TeamQuery,
} from "../queries";
import type { Team, TemplateData, ZendeskTicket } from "../types";
import { Constants } from "../constants";
import { attachmentForZendeskConversation } from "../utils/formattingUtils";

const NewIssueSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  team: Yup.string().required("Required"),
  priority: Yup.number(),
  assignee: Yup.string(),
  label: Yup.string(),
});

type Props = {
  title?: string;
  description?: string;
  templateData?: TemplateData;
  ticket: ZendeskTicket;
  teams: Team[];
  priorities: IViewerQuery["issuePriorityValues"];
  onCreate(): void;
  onCancel(): void;
};

interface FormValues {
  title: string;
  description: string;
  includeMessage: boolean;
  team: string;
  priority?: number;
  assignee?: string;
  labels: string[];
}

export function CreateForm(props: Props) {
  const { ticket, teams, priorities, templateData, onCreate, onCancel } = props;
  const { id: templateId } = templateData || {};
  const textareaRef = React.createRef<HTMLTextAreaElement>();
  const [initialTeamId] = React.useState(
    templateData?.teamId || localStorage.getItem(Constants.teamCacheKey) || teams[0]?.id
  );
  const [includeMessage] = React.useState<boolean>(
    localStorage.getItem(Constants.includeMessagePreferenceKey) === "true"
  );
  const [createIssueResult, createIssue] = useMutation<ICreateIssueMutation>(CreateIssueMutation);
  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const fetching = createIssueResult.fetching || createAttachmentResult.fetching;
  const quotedMessage = React.useMemo(
    () =>
      ticket.messages[0]?.body
        .trim()
        .split("\n")
        .map(row => `> ${row}`)
        .join("\n"),
    [ticket]
  );

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (fetching) {
      setSubmitting(false);
      return;
    }

    localStorage.setItem(Constants.teamCacheKey, values.team);
    const issueResult = await createIssue({
      teamId: values.team,
      title: values.title,
      description: values.description,
      assigneeId: values.assignee,
      labelIds: values.labels || [],
      priority: values.priority,
      templateId,
    });
    const issue = issueResult.data?.issueCreate?.issue;
    if (issue) {
      await createAttachment(attachmentForZendeskConversation(issue.id, ticket));
      setSubmitting(false);
      onCreate();
    }
  };

  function handleIncludeMessage(form: FormikProps<FormValues>, checked: boolean) {
    if (checked) {
      void form.setFieldValue("description", `${quotedMessage}\n\n${form.values.description}`);
    } else if (form.values.description.startsWith(quotedMessage)) {
      void form.setFieldValue("description", form.values.description.replace(quotedMessage, "").trim());
    }
    localStorage.setItem(Constants.includeMessagePreferenceKey, checked.toString());
  }

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          title: props.title || templateData?.title || ticket.subject || "",
          includeMessage,
          description: props.description || templateData?.description || (includeMessage ? quotedMessage : ""),
          team: initialTeamId,
          labels: templateData?.labelIds || [],
          assignee: templateData?.assigneeId,
          priority: templateData?.priority,
        }}
        validationSchema={NewIssueSchema}
        onSubmit={handleSubmit}
      >
        {(form: FormikProps<FormValues>) => (
          <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
            <Field>
              <Label>Title</Label>
              <FormikField name="title">
                {({ field }: FieldProps<string, FormValues>) => (
                  <>
                    <Input isCompact autoFocus {...field} />

                    {form.submitCount > 0 && form.touched.title && form.errors.title && (
                      <Message validation="error">{form.errors.title}</Message>
                    )}
                  </>
                )}
              </FormikField>
            </Field>
            <Field>
              <Label>Description</Label>
              <FormikField name="description">
                {({ field }: FieldProps<string, FormValues>) => (
                  <Textarea minRows={4} maxRows={10} isCompact {...field} />
                )}
              </FormikField>
            </Field>
            <Field>
              <FormikField name="includeMessage" type="checkbox">
                {({ field }: FieldProps<string, FormValues>) => (
                  <Toggle
                    id="includeMessage"
                    {...field}
                    onChange={e => {
                      handleIncludeMessage(form, e.target.checked);
                      field.onChange(e);
                      textareaRef.current?.focus();
                    }}
                  >
                    <Label htmlFor="includeMessage" isRegular>
                      Include message
                    </Label>
                  </Toggle>
                )}
              </FormikField>
            </Field>

            <FormikField name="team">
              {({ field }: FieldProps<string, FormValues>) => {
                const selectedTeam = field.value ? teams.find(team => team.id === field.value) : undefined;
                return (
                  <FilteredDropdown
                    selectedItem={field.value}
                    onSelect={teamId => {
                      void form.setFieldValue("team", teamId);
                    }}
                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                    options={teams}
                    nameForOption={team => team.displayName}
                  >
                    {({ matchingOptions }) => (
                      <>
                        <DropdownField>
                          <Label>Team</Label>
                          <Autocomplete isCompact>{selectedTeam?.displayName}</Autocomplete>
                          {form.submitCount > 0 && form.touched.team && form.errors.team && (
                            <Message validation="error">{form.errors.team}</Message>
                          )}
                        </DropdownField>
                        <Menu isCompact>
                          {matchingOptions.length ? (
                            matchingOptions.map(team => (
                              <Item key={team.id} value={team.id}>
                                <span>{team.displayName}</span>
                              </Item>
                            ))
                          ) : (
                            <Item disabled>No matches found</Item>
                          )}
                        </Menu>
                      </>
                    )}
                  </FilteredDropdown>
                );
              }}
            </FormikField>

            <FormikField name="priority">
              {({ field }: FieldProps<number, FormValues>) => {
                const selectedPriority = field.value
                  ? priorities.find(priority => priority.priority === field.value)
                  : undefined;
                return (
                  <FilteredDropdown
                    selectedItem={field.value}
                    onSelect={priority => {
                      void form.setFieldValue("priority", priority);
                    }}
                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                    options={priorities}
                    nameForOption={priority => priority.label}
                  >
                    {({ matchingOptions }) => (
                      <>
                        <DropdownField>
                          <Label>Priority</Label>
                          <Autocomplete isCompact>{selectedPriority?.label}</Autocomplete>
                        </DropdownField>
                        <Menu isCompact>
                          {matchingOptions?.length ? (
                            matchingOptions.map(priority => (
                              <Item key={priority.priority} value={priority.priority}>
                                <span>{priority.label}</span>
                              </Item>
                            ))
                          ) : (
                            <Item disabled>No matches found</Item>
                          )}
                        </Menu>
                      </>
                    )}
                  </FilteredDropdown>
                );
              }}
            </FormikField>

            <TeamFormFields form={form} teamId={form.values.team} />

            <Buttons>
              <Button isPrimary type="submit" disabled={form.isSubmitting}>
                {form.isSubmitting ? "Creating…" : "Create issue"}
              </Button>
              <Button isBasic onClick={onCancel}>
                Cancel
              </Button>
            </Buttons>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 4px;
`;

const StyledForm = styled(Form)`
  > div {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  padding-top: 12px;
  background-color: ${props => props.theme.colors.background};

  > button:first-child {
    margin-right: 12px;
  }
`;

// -- Team related form fields

const TeamFormFields = ({ teamId, form }: { form: FormikProps<FormValues>; teamId?: string }) => {
  const [result] = useQuery<ITeamQuery>({
    query: TeamQuery,
    variables: { teamId },
    pause: teamId === undefined,
  });
  const loaded = result.fetching === false && result.data;
  const users = result.data?.team?.members.nodes || [];
  const labels = result.data?.team?.labels.nodes || [];

  // filter labels to not include groups and sort by name
  const labelGroups = labels.filter(label => labels.find(l => l.parent?.id === label.id));
  const rootLabels = labels.filter(label => !labelGroups.includes(label));

  const labelOptions = SortHelper.natural(rootLabels, label => `${label.parent?.name || ""} ${label.name}`).map(
    label => ({
      ...label,
      name: label.parent ? `${label.parent.name} → ${label.name}` : label.name,
    })
  );

  return (
    <>
      <FormikField name="assignee">
        {({ field }: FieldProps<string, FormValues>) => {
          const assignee = field.value && users ? users.find(user => user.id === field.value) : undefined;
          return (
            <FilteredDropdown
              selectedItem={field.value}
              onSelect={userId => {
                void form.setFieldValue("assignee", userId);
              }}
              downshiftProps={{ defaultHighlightedIndex: 0 }}
              options={SortHelper.natural(users, "name")}
              nameForOption={user => user.name}
            >
              {({ matchingOptions }) => (
                <>
                  <DropdownField>
                    <Label>Assignee</Label>
                    <Autocomplete isCompact disabled={!loaded}>
                      {assignee?.name}
                    </Autocomplete>
                    {form.submitCount > 0 && form.touched.assignee && form.errors.assignee && (
                      <Message validation="error">{form.errors.assignee}</Message>
                    )}
                  </DropdownField>
                  <Menu isCompact>
                    {matchingOptions?.length ? (
                      matchingOptions.map(user => (
                        <Item key={user.id} value={user.id}>
                          <span>{user.name}</span>
                        </Item>
                      ))
                    ) : (
                      <Item disabled>No matches found</Item>
                    )}
                  </Menu>
                </>
              )}
            </FilteredDropdown>
          );
        }}
      </FormikField>
      <FieldArray name="labels">
        {() => {
          return (
            <FilteredDropdown
              selectedItems={form.values.labels}
              onSelect={labelIds => {
                void form.setFieldValue(
                  "labels",
                  // Enforce unique labels within groups. Reverse ensures that the last selected label is the one that
                  // is retained.
                  uniqBy(labelIds.reverse(), labelId => {
                    const label = labelOptions.find(l => l.id === labelId);
                    return label?.parent?.id || labelId;
                  }).reverse()
                );
              }}
              downshiftProps={{ defaultHighlightedIndex: 0 }}
              options={labelOptions}
              nameForOption={label => label.name}
            >
              {({ matchingOptions }) => (
                <>
                  <DropdownField>
                    <Label>Labels</Label>
                    <Multiselect
                      isCompact
                      renderItem={({ value, removeValue }) => {
                        const label = labels.find(l => l.id === value);
                        return (
                          <Tag>
                            <span>{label?.name || value}</span>
                            <Tag.Close onClick={removeValue} />
                          </Tag>
                        );
                      }}
                    />
                  </DropdownField>
                  <Menu isCompact>
                    {matchingOptions?.length ? (
                      matchingOptions.map(label => (
                        <Item key={label.id} value={label.id}>
                          <span>{label.name}</span>
                        </Item>
                      ))
                    ) : (
                      <Item disabled>No matches found</Item>
                    )}
                  </Menu>
                </>
              )}
            </FilteredDropdown>
          );
        }}
      </FieldArray>
    </>
  );
};

interface FilteredDropdownProps extends Omit<IDropdownProps, "children"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children(renderProps: { matchingOptions: any[] }): React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nameForOption(model: any): string;
}

/**
 * Auto-completing dropdown with user filtering.
 */
const FilteredDropdown = (props: FilteredDropdownProps) => {
  const { options, nameForOption, children, ...rest } = props;
  const [input, setInput] = React.useState<string>("");
  const [matchingOptions, setMatchingOptions] = React.useState(props.options);

  React.useEffect(() => {
    if (!input || input.length === 0) {
      setMatchingOptions(props.options);
      return;
    }

    const matchedOptions = props.options.filter(
      option => props.nameForOption(option).toLowerCase().indexOf(input.trim().toLowerCase()) !== -1
    );

    setMatchingOptions(matchedOptions);
  }, [props.options, input]);

  return (
    <Dropdown
      {...rest}
      inputValue={input}
      onInputValueChange={value => setInput(value)}
      downshiftProps={{ defaultHighlightedIndex: 0 }}
    >
      {children({ matchingOptions })}
    </Dropdown>
  );
};
